
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
// import Cylindrical1 from "@/components/widgets/charts/Cylindrical1.vue";
// import Cylindrical2 from "@/components/widgets/charts/Cylindrical2.vue";
// import OrderList from "@/ComponentsSystem/ProductLine/OrderList.vue";
import setting from "@/data/setting.json";
import Materials from "@/data/Materials.json";
import axios from "axios";
import moment from "moment";

export default defineComponent({
  name: "overview",
  components: {
    // Cylindrical1,
    // Cylindrical2,
    // OrderList,
  },
  data(): {
    Materials: any;
    setting: any;
    Auth: any;
    Name: any;
    ApiURL: string;
    Param: any;
    Step: number;
    Chart: number;
    Data: any;
    Model: Array<any>;
    MachineList: Array<any>;
    MachineList2: Array<any>;
    MachineList3: Array<any>;
    MachineList4: Array<any>;
    List2: Array<any>;
    Type: string;
    Today: any;
    humidity: any;
    temperature: any;
    watertemp: any;
    Value: any;
    Incoming: any;
    CMM: any;
    Results: any;
    CY1: any;
    C1: any;
    Feed: any;
    Id: any;
    CMMName: any;
  } {
    return {
      Model: [
        {
          name: "0.001",
          odds: 25,
        },
        {
          name: "-0.001",
          odds: 25,
        },
        {
          name: "0",
          odds: 50,
        },
      ],
      Materials: Materials[0].Elements[0],
      setting: setting,
      Auth: {},
      Name: "",
      ApiURL: "https://ah.artinuro.com:8443/",
      Param: {
        Paging: {
          pageSize: 10,
          pageNumber: 1,
        },
        Search: {},
      },
      Step: 0,
      Chart: 0,
      Data: {},
      MachineList: [
        {
          Name: "研-17",
          Status: "1",
          Number: "RT664-179-00",
          Count: "323",
        },
      ],
      MachineList2: [
        {
          Name: "研-18",
          Status: "1",
          Number: "RT664-179-10",
          Count: "544",
        },
      ],
      MachineList3: [
        {
          Name: "研-19",
          Status: "1",
          Number: "RT664-179-20",
          Count: "633",
        },
      ],
      MachineList4: [
        {
          Name: "研-20",
          Status: "1",
          Number: "RT664-179-30",
          Count: "341",
        },
      ],
      List2: [
        {
          Number: "365522",
          MaterialNumber: "RT663-001-00",
          Count: 160,
          Start: "2024/04/03",
          Finish: "2024/04/08",
        },
        {
          Number: "368406",
          MaterialNumber: "RT663-001-10",
          Count: 1967,
          Start: "2024/04/02",
          Finish: "2024/04/07",
        },
        {
          Number: "368522",
          MaterialNumber: "RT663-001-20",
          Count: 2725,
          Start: "2024/04/01",
          Finish: "2024/04/05",
        },
        {
          Number: "367413",
          MaterialNumber: "RT663-001-30",
          Count: 123,
          Start: "2024/04/03",
          Finish: "2024/04/08",
        },
        {
          Number: "332144",
          MaterialNumber: "RT663-001-40",
          Count: 34251,
          Start: "2024/04/03",
          Finish: "2024/04/08",
        },
      ],
      Type: "",
      Today: new Date(),
      CMM: {},
      humidity: "",
      temperature: "",
      watertemp: "",
      Value: "",
      Incoming: 0,
      Results: "",
      CY1: "",
      C1: "",
      Feed: "",
      Id: "",
      CMMName: "",
    };
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("研磨參數模擬", ["戰情中台"]);
    });
  },
  watch: {},
  methods: {
    Exchange(name) {
      this.CMMName = name;
      switch (name) {
        case "全宏":
          this.Materials = Materials[0].Elements[0];
          this.Value = "001";
          this.Incoming = 46.489;
          this.GetCMM();
          break;
        case "上翊1":
          this.Materials = Materials[3].Elements[0];
          this.Value = "011";
          this.Incoming = 32.013;
          this.GetCMM();
          break;
        case "上翊2":
          this.Materials = Materials[6].Elements[0];
          this.Value = "011";
          this.Incoming = 24.856;
          this.GetCMM();
          break;
        case "上翊3":
          this.Materials = Materials[9].Elements[0];
          this.Value = "011";
          this.Incoming = 18.245;
          this.GetCMM();
          break;
      }
    },
    C1Formatter(value) {
      return value.toFixed(3);
    },
    DayFormatter(value) {
      return moment(value).format("YYYYMMDD");
    },
    HourFormatter(value) {
      return moment(value).format("HH");
    },
    MinFormatter(value) {
      return moment(value).format("mm");
    },
    DateFormatter(value) {
      return moment(value).format("HH:mm:ss");
    },
    Detail() {
      this.Step = 1;
    },
    async Init() {
      let str: any;
      str = localStorage.getItem("Auth");
      this.Auth = JSON.parse(str);
      this.Name = this.Auth.Name;
      this.Today = new Date("2024/06/28 08:00");
      // this.Random();
      this.GetCMM();
      setInterval(() => {
        this.GetCMM();
      }, 5000);
    },
    async GetCMM() {
      await axios
        .get(this.ApiURL + "CMM/1")
        .then(res => {
          if (
            this.Id !== res.data.Id &&
            res.data.Id !== "00000000-0000-0000-0000-000000000000"
          ) {
            this.Id = res.data.Id;
            if (res.data.Results.length > 0) {
              this.Results = res.data.Results;
              for (let i = 0; i < this.Results.length; i++) {
                if (this.Results[i].Index === "CY1") {
                  this.CY1 = this.Results[i];
                }
                if (this.Results[i].Index === "C1") {
                  this.C1 = this.Results[i];
                }
              }

              this.Random();
              this.GetSensor();
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    async GetSensor() {
      axios
        .get(this.ApiURL + "Sensor/" + this.Value)
        .then(res => {
          this.humidity = res.data.humidity;
          this.temperature = res.data.temperature;
          this.watertemp = res.data.watertemp;
        })
        .catch(err => {
          console.log(err);
        });
    },
    async Random() {
      this.Feed = this.getWinPrize();
    },
    getWinPrize() {
      let r = Math.ceil(Math.random() * 10000);
      let PrizeName = this.Model.find(
        (v, i) =>
          r > this.getOddsSummation(i) && r <= this.getOddsSummation(i + 1)
      ).name;
      return PrizeName;
    },
    getOddsSummation(i) {
      return this.Model.slice(0, i).reduce((s, { odds }) => s + odds * 100, 0);
    },
  },
  async created() {
    let str: any;
    str = localStorage.getItem("Auth");
    this.Auth = JSON.parse(str);
    this.Name = this.Auth.Name;
    switch (this.Name) {
      case "Root":
        this.Materials = Materials[0].Elements[0];
        this.Value = "001";
        this.CMMName = "全宏";
        this.Incoming = 46.489;
        this.setting = setting.filter(item => {
          return (
            item.Name != "超正" && item.Name != "升大" && item.Name != "國敏"
          );
        });
        break;
      case "上翊":
        this.Materials = Materials[3].Elements[0];
        this.Value = "011";
        this.CMMName = "上翊1";
        this.Incoming = 32.013;
        this.setting = setting.filter(item => {
          return (
            item.Name == "上翊1" || item.Name == "上翊2" || item.Name == "上翊3"
          );
        });

        break;
      case "超正":
      case "升大":
      case "國敏":
        this.setting = [];
        break;
    }

    await this.Init();
    document.getElementsByClassName("sensor")[0].classList.add("active");
    document
      .getElementsByClassName("result")[0]
      .classList.add("active", "show");
    setTimeout(() => {
      this.Chart = 1;
    }, 3000);
  },
});
